import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Dialog, IconButton } from "@material-ui/core";
import { toggleDialog } from "../../../../../actions";
// Images and icons
import CloseIcon from "@material-ui/icons/Close";
// Styles
import "../index.scss";

class Cookies extends PureComponent {
  onClose = () => {
    this.props.toggleDialog("cookies", false);
  };
  render() {
    const { t: _t, isShow } = this.props;
    return (
      <Dialog fullWidth maxWidth={"md"} className="footerDialog" open={isShow} onClose={this.onClose}>
        <div className="footerDialog__wrap">
          <IconButton className="footerDialog__close" onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
          <h2>{_t("Cookies")}</h2>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero qui temporibus earum laboriosam? Eius
            fugiat officia tempore dicta laboriosam expedita? Numquam amet sed odit, tenetur alias asperiores modi
            voluptates deleniti. Magnam, rec
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero qui temporibus earum laboriosam? Eius
            fugiat officia tempore dicta laboriosam expedita? Numquam amet sed odit, tenetur alias asperiores modi
            voluptates deleniti. Magnam, rec
          </p>
        </div>
      </Dialog>
    );
  }
}

Cookies.propTypes = {
  t: propTypes.func.isRequired,
  isShow: propTypes.bool.isRequired,
};
const mapStateToProps = ({ cookies }) => ({
  isShow: cookies.isShow,
});

const mapDispatchToProps = {
  toggleDialog,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Cookies));
