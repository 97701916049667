import React, { PureComponent } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
// Styles
import "./index.scss";

class Socials extends PureComponent {
  render() {
    return (
      <div className="homePage__socials">
        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank" className="homePage__social">
          <FacebookIcon />
        </a>
        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank" className="homePage__social">
          <InstagramIcon />
        </a>
        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank" className="homePage__social">
          <YouTubeIcon />
        </a>
      </div>
    );
  }
}

export default Socials;
