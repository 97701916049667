import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { toggleDialog } from "../../../actions";
// Components
import TermsOfUse from "./Dialogs/TermsOfUse";
import TermsConditions from "./Dialogs/TermsConditions";
import Cookies from "./Dialogs/Cookies";
import ContactUs from "./Dialogs/ContactUs";
import Socials from "../Socials";
// Styles
import "./index.scss";

class Footer extends PureComponent {
  render() {
    const { t: _t, toggleDialog, isTermsOfUse, isTermsConditions, isCookies, isContactUs } = this.props;
    return (
      <footer className="outerFooter">
        <div className="outerFooter__btns">
          <Button
            onClick={() => {
              toggleDialog("termsConditions", true);
            }}
          >
            {_t("Terms & Conditions")}
          </Button>
          <Button
            onClick={() => {
              toggleDialog("termsOfUse", true);
            }}
          >
            {_t("Terms of Use")}
          </Button>
          <Button
            onClick={() => {
              toggleDialog("contactUs", true);
            }}
          >
            {_t("Contact Us")}
          </Button>
          <Button
            onClick={() => {
              toggleDialog("cookies", true);
            }}
          >
            {_t("Cookie policy")}
          </Button>
        </div>
        <Socials />
        <a href="https://colleagial.com/premium-offers/" target="_blank" rel="noopener noreferrer" className="outerFooter__development">
          Colleagial Studio
        </a>
        {isTermsOfUse && <TermsOfUse />}
        {isTermsConditions && <TermsConditions />}
        {isContactUs && <ContactUs />}
        {isCookies && <Cookies />}
      </footer>
    );
  }
}

Footer.propTypes = {
  t: propTypes.func.isRequired,
};
const mapStateToProps = ({ termsOfUse, termsConditions, cookies, contactUs }) => ({
  isTermsOfUse: termsOfUse.isShow,
  isTermsConditions: termsConditions.isShow,
  isCookies: cookies.isShow,
  isContactUs: contactUs.isShow,
});

const mapDispatchToProps = {
  toggleDialog,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Footer));
