import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import propTypes from "prop-types";

import Button from "@material-ui/core/Button";

import Socials from "./Socials";
import Footer from "./Footer";

import "./index.scss";

class LoginLayout extends PureComponent {

  render () {
    const {t: _t, isAuth} = this.props;
    if (isAuth) {
      return <Redirect to="/profile" />;
    }
    return (
      <div className="homePage">
        <header className="homePage__header">
          <nav className="homePage__nav">
            <div className="container">
              <Link to="/"
                className="homePage__logo">
                <span>Academy</span>
                <span>Mesh</span>
                <span>Align</span>
              </Link>
              <div className="homePage__actions">
                <Socials />
                <Button component={Link}
                  to="/login"
                  className="homePage__login">
                  {_t("Войти")}
                </Button>
              </div>
            </div>
          </nav>
          <div className="container">
            <div className="homePage__main">
              <div className="homePage__title">
                <img src={require("../../assets/img_ama/favicon.png")}
                  alt="Digital Academy MeshAlign" />
                <h1>
                  Digital Academy <span>MeshAlign</span>
                </h1>
              </div>
              <h2 className="homePage__description">
                {_t("Веб платформа для обучения полному циклу производства систем элайнеровлюбой сложности.")}
              </h2>
              <h3 className="homePage__text">
                {_t(
                  "Элайнеры - это одно из самых динамично развивающееся направлении в ортодонтии и стоматологии в целом. Начните планировать лечение элайнерами и производство элайнеров в вашей клинике или зуботехнической лаборатории с помощю данного ресурса.",
                )}
              </h3>
              <Button component={Link}
                to="/register"
                variant="contained"
                color="primary">
                {_t("Зарегистрироваться")}
              </Button>
            </div>
          </div>
        </header>
        <section className="manufacturing">
          <h2>{_t("Изготовление элайнеров в зуботехнической лаборатории или клинике это:")}</h2>
          <div className="manufacturing__items">
            <div className="manufacturing__item">
              <div className="manufacturing__pic">
                <img src={require("../../assets/img_ama/Sale_Icon_1.png")}
                  alt="" />
              </div>
              <h6>{_t("Увеличение продаж")}</h6>
            </div>
            <div className="manufacturing__item">
              <div className="manufacturing__pic">
                <img src={require("../../assets/img_ama/Cube_Send_Icon_1.png")}
                  alt="" />
              </div>
              <h6>{_t("Увеличение ассортимента")}</h6>
            </div>
            <div className="manufacturing__item">
              <div className="manufacturing__pic">
                <img src={require("../../assets/img_ama/Account_Settings_Icon_1.png")}
                  alt="" />
              </div>
              <h6>{_t("Привлечение новых клиентов")}</h6>
            </div>
          </div>
        </section>
        <section className="steps">
          <div className="container">
            <h2>{_t("Этапы обучения")}</h2>
            <p className="steps__subtitle">
              {_t(
                "Этапы обучения на платформе Academy MeshAlign разделены на уровни. Всего 5 уровней. Каждый уровень дает определенный набор знаний, который позволяет выполнять определенные типы работ разной сложности. Все уровни разделены на блоки. Использование ресурса необходимо не только на этапе обучения производству элайнеров, а так же в процессе работы. Шаблоны и протоколы, представленные на данной интернет платформе позволят получать качественный и повторяющийся результат.",
              )}
            </p>
            <div className="steps__items">
              <div className="steps__item">
                <div className="steps__num">1</div>
                <div className="steps__text">
                  {_t(
                    "Первый уровень обучения дает полные знания для производства элайнеров от момента получения готовых моделей для 3D печати, а так же несет в себе вводные знания о создании планов ортодонтического лечения.",
                  )}
                </div>
              </div>
              <div className="steps__item">
                <div className="steps__num">2</div>
                <div className="steps__text">
                  {_t(
                    "Второй уровень обеспечивает пользователя базовыми знаниями, необходимыми для создании планов ортодонтического лечения, обучает основным понятиям в ортодонтии, а так же рассказывает о подготовительных этапов к планированию.",
                  )}
                </div>
              </div>
              <div className="steps__item">
                <div className="steps__num">3</div>
                <div className="steps__text">
                  {_t(
                    "Третий уровень обучения дает полные знания для планирования ортодонтического лечения элайнерами средних и легких клинических случаев.",
                  )}
                </div>
              </div>
              <div className="steps__item">
                <div className="steps__num">4</div>
                <div className="steps__text">
                  {_t(
                    "Прохождение четвертого уровня обучения обеспечивает пользователя знаниями, достаточными для планирования  сложных случаев лечения ортодонтических аномалий элайнерами.",
                  )}
                </div>
              </div>
              <div className="steps__item">
                <div className="steps__num">5</div>
                <div className="steps__text">
                  {_t(
                    "Пятый уровень обеспечивает пользователя знаниями и материалами, необходимыми для планирования полной эстетической реабилитации пациента после ортодонтического лечения элайнерами.",
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="lessons">
          <div className="container">
            <h2
              className="lessons__title">{_t("Уровень 1 \"Стартовый\" (бесплатно, без регистрации)")}</h2>
            <div className="cards">
              <div className="cards__card big">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b1.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Что такое элайнеры")}</h6>
                  <p>{_t("Этапы изготовления.")}</p>
                  <p>{_t("Биомеханика.")}</p>
                  <p>{_t("Показания.")}</p>
                  <p>{_t("Противопоказания.")}</p>
                </div>
                <Button component={Link}
                  to="/what-are-aligners"
                  variant="outlined"
                  color="primary">
                  {_t("Просмотреть раздел")}
                </Button>
              </div>
              <div className="cards__card big">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b2.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Изготовление элайнеров")}</h6>
                  <p>{_t("3D печать моделей.")}</p>
                  <p>{_t("Изготовление элайнеров.")}</p>
                  <p>{_t("Подготовка, упаковка.")}</p>
                </div>
                <Button component={Link}
                  to="/aligners-manufacturing"
                  variant="outlined"
                  color="primary">
                  {_t("Просмотреть раздел")}
                </Button>
              </div>
              <div className="cards__card big">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b3.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Базовые знания необходимые для производства элайнеров")}</h6>
                  <p>{_t("Принципы работы элайнеров.")}</p>
                  <p>{_t("Основные классификации.")}</p>
                  <p>{_t("Возможности элайнеров.")}</p>
                </div>
                <Button component={Link}
                  to="/basic-aligners"
                  variant="outlined"
                  color="primary">
                  {_t("Просмотреть раздел")}
                </Button>
              </div>
            </div>
            <h2
              className="lessons__title">{_t("Уровень 2 \"Базовый\" (бесплатно, регистрация обязательна)")}</h2>
            <div className="lessons__btnWrap">
              <Button component={Link}
                to="/register"
                variant="contained"
                color="primary">
                {_t("Зарегистрироваться")}
              </Button>
            </div>
            <div className="cards">
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b4.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Словарь ортодонтических терминов")}</h6>
                  <p>{_t("Основные ортодонтические термины.")}</p>
                  <p>{_t("Краткое описание методик.")}</p>
                  <p>{_t("Расшифровка базовых понятий.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b5.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Подготовка цифровых моделей")}</h6>
                  <p>{_t("Обработка 3D сканов челюстей.")}</p>
                  <p>{_t("Подготовка моделей к ортодонтической постановке для дальнейшего производства элайнеров.")}</p>
                  <p>{_t("Видео уроки.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b6.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Классификация ортодонтических аномалий")}</h6>
                  <p>
                    {_t(
                      "Концепция планирования лечения ортодонтических аномали с использованием классификации и протоколов.",
                    )}
                  </p>
                </div>
              </div>
            </div>
            <h2
              className="lessons__title">{_t("Уровень 3 \"Профессиональный\" (стоимость 200$)")}</h2>
            <div className="cards">
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b7.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Обзор 3D программ")}</h6>
                  <p>{_t("Видео уроки.")}</p>
                  <p>{_t("Создание системы элайнеров для лечения легких случаев в бесплатной программе.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b8.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Протоколы планирования ортодонтического лечения элайнерами")}</h6>
                  <p>{_t("Легкие и средней сложности случаи.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b9.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Аналитические протоколы")}</h6>
                  <p>{_t("Анализ панорамного снимка.")}</p>
                  <p>{_t("Расчет ширины зубных дуг.")}</p>
                  <p>{_t("Расчет дефицита места на челюсти.")}</p>
                  <p>{_t("Расчет ТРГ боковой проекции.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b10.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Протоколы использования элайнеров вместе с системами ортодонтических эластиков")}</h6>
                  <p>{_t("Эластики для ротации, экструзии, интрузии.")}</p>
                  <p>{_t("Эластики Сl II, Сl III и т.д.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b11.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Методика использования активаторов (аттачментов)")}</h6>
                  <p>{_t("Типы аттачментов.")}</p>
                  <p>{_t("Изготовление шаблона для фиксации аттачментов.")}</p>
                  <p>{_t("Файлы для скачивания.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b12.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Клинические протоколы")}</h6>
                  <p>{_t("Протокол фиксации активаторов.")}</p>
                  <p>{_t("Протоколы использования эластических тяг.")}</p>
                  <p>{_t("Фото протокол.")}</p>
                  <p>{_t("Рекомендации пациентам.")}</p>
                </div>
              </div>
            </div>
            <h2 className="lessons__title">{_t("Уровень 4 \"Углубленный\" (стоимость 200$)")}</h2>
            <div className="cards">
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b13.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Протоколы планирования ортодонтического лечения элайнерами")}</h6>
                  <p>{_t("Сложные случаи.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b14.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Анализ КТ")}</h6>
                  <p>{_t("Анализ костного массива.")}</p>
                  <p>{_t("Анализ положения сустава в суставной ямке.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b15.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Анализ улыбки пациента")}</h6>
                  <p>{_t("Анализ фото пациента.")}</p>
                  <p>{_t("Цифровые шаблоны для анализа улыбки.")}</p>
                  <p>{_t("Расширенный фото протокол.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b16.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Политика компаний")}</h6>
                  <p>{_t("Протоколы сотрудничества.")}</p>
                  <p>{_t("Шаблоны договоров.")}</p>
                  <p>{_t("Документация.")}</p>
                </div>
              </div>
            </div>
            {" "}
            <h2 className="lessons__title">{_t("Уровень 5 \"Комплексный\" (стоимость 200$)")}</h2>
            <div className="cards">
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b17.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>
                    {_t("Планирование эстетической реабилитации пациента после ортодонтического лечения элайнерами.")}
                  </h6>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b18.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Библиотеки зубов для дизайна улыбки и моделирования цифровых мокапов")}</h6>
                  <p>{_t("Работа с библиотеками.")}</p>
                  <p>{_t("Полный доступ для скачивания библиотек.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b19.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Цифровой дизайн улыбки пациента")}</h6>
                  <p>{_t("Видео уроки.")}</p>
                  <p>{_t("Шаблоны для дизайна улыбки.")}</p>
                  <p>{_t("Работа с библиотекой улыбок.")}</p>
                </div>
              </div>
              <div className="cards__card">
                <div className="cards__pic">
                  <img src={require("../../assets/img_ama/b20.jpg")}
                    alt="" />
                </div>
                <div className="cards__text">
                  <h6>{_t("Цифровой дизайн мокапа")}</h6>
                  <p>{_t("3D дизайн будущих реставраций.")}</p>
                  <p>{_t("Работа с 3D библиотеками зубов.")}</p>
                  <p>{_t("Обзор специальных программ.")}</p>
                  <p>{_t("Видео уроки.")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

LoginLayout.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({login}) => ({isAuth: login.isAuth});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(LoginLayout));
