import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Dialog, TextField, IconButton, Button, CircularProgress } from "@material-ui/core";
import { toggleDialog } from "../../../../../actions";
import { Formik, Field, Form } from "formik";
import { API } from "../../../../../modules/Api";
import * as Yup from "yup";
// Images and icons
import CloseIcon from "@material-ui/icons/Close";
// Styles
import "../index.scss";

class ContactUs extends PureComponent {
  state = {
    recaptcha: "",
    isLoading: false,
    isError: false,
  };

  onlineSchema = () => {
    const {t: _t} = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .email(_t("Invalid email"))
        .required(_t("Required")),
    });
  };

  onlineSend = ({email, text}) => {
    this.setState({isLoading: true});
    API.post("/contact_us/new", {email, text, recaptcha: this.state.recaptcha}).then(
      response => {
        this.setState({isLoading: false});
        console.log(response);
        this.onClose();
      },
      error => {
        this.setState({isLoading: false, isError: true}, () => {
          setTimeout(() => {
            this.setState({isError: false});
          }, 3000);
        });
        console.log(error);
      },
    );
  };
  onClose = () => {
    this.props.toggleDialog("contactUs", false);
  };

  render () {
    const {t: _t, isShow} = this.props;
    const {isLoading, isError} = this.state;
    return (
      <Dialog fullWidth
        maxWidth={"md"}
        className="footerDialog"
        open={isShow}
        onClose={this.onClose}>
        <div className="footerDialog__wrap">
          {isLoading && (
            <div className="overlay">
              <CircularProgress size={80} />
            </div>
          )}
          {isError && (
            <div className="overlay">
              <span>{_t("Something went wrong. Message has not been sent. Try again later.")}</span>
            </div>
          )}
          <IconButton className="footerDialog__close"
            onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
          <Formik
            initialValues={{
              email: "",
              text: "",
            }}
            validationSchema={this.onlineSchema}
            onSubmit={this.onlineSend}>
            {({errors}) => (
              <Form className="form">
                <Field name="text">{({field}) => (
                  <TextField
                    margin="dense"
                    label={_t("Message")}
                    multiline
                    fullWidth
                    rows="2"
                    rowsMax="40"
                    {...field}
                    className="textarea"
                  />
                )}
                </Field>
                <Field
                  name="email"
                  render={({field}) => (
                    <TextField
                      type="email"
                      label={_t("Email")}
                      margin="dense"
                      fullWidth
                      className="input"
                      error={!!errors.email}
                      helperText={errors.email}
                      {...field}
                    />
                  )}
                />
                <div className="btnWrap">
                  <Button type="submit"
                    className="btn">
                    {_t("Send")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

ContactUs.propTypes = {
  t: propTypes.func.isRequired,
  isShow: propTypes.bool.isRequired,
};
const mapStateToProps = ({contactUs}) => ({
  isShow: contactUs.isShow,
});

const mapDispatchToProps = {
  toggleDialog,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ContactUs));
